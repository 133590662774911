<template>
  <div class="aiUndress">
    <div class="navBar">
      <div @click="$router.go(-1)">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">AI科技</div>
      <div class="rightBtn" @click="$router.push('/aiRecord')">记录</div>
    </div>
    <div class="list overflow-y-auto">
      <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push(item.router)">
        <div class="bg" :class="[`bg-${index + 1}`]"></div>
        <div class="title">
          <div class="icon" :class="[`icon-${index + 1}`]"></div>
          {{ item.name }}
          <span class="label">（{{ item.label }}）</span>
          <span class="beta" v-if="item.isBeta">beta</span>
        </div>
      </div>
      <div style="height: 44px"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'aiUndress',
    data() {
      return {
        list: [
          {
            name: 'AI女友',
            label: '聊天',
            isBeta: false,
            router: '/AiGirlFriend',
          },
          {
            name: 'AI换脸',
            label: '图片',
            isBeta: true,
            router: '/aiUndress2?active=2',
          },
          {
            name: 'AI换脸',
            label: '视频',
            isBeta: true,
            router: '/aiUndress2?active=1',
          },
          {
            name: 'AI脱衣',
            label: '图片',
            isBeta: true,
            router: '/aiUndress2?active=0',
          },
        ],
      };
    },
  };
</script>
<style lang="scss" scoped>
  .aiUndress {
    height: 100%;
    background: #f5f5f5;
    .navBar {
      height: 44px;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      // border-bottom: 1px solid #e6e6e6;
      .navArrow {
        width: 17px;
        height: 17px;
        margin-right: 26px;
      }
      .navTitle {
        flex: 2;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .rightBtn {
        color: #666666;
      }
    }
    .list {
      height: calc(100% - 44px);
      $items: (1, 2, 3, 4);

      @each $value in $items {
        // '../../../assets/png/AiGirlFriend-btn.png'
        .bg-#{$value} {
          background: url('../../../assets/png/aiUndress-bg-#{$value}.gif') 0 0 / cover no-repeat;
        }
        .icon-#{$value} {
          background: url('../../../assets/png/aiUndress-icon-#{$value}.png') 0 0 / contain no-repeat;
        }
      }

      padding: 0 18px;
      .item {
        width: 100%;
        margin-top: 12px;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;
        .bg {
          height: 180px;
        }
        .title {
          height: 53px;
          border-radius: 0px 0px 8px 8px;
          background: rgba(0, 0, 0, 0.04);
          display: flex;
          align-items: center;
          padding: 0 16px;

          color: #333;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: 26.25px; /* 154.412% */
          .icon {
            width: 15px;
            height: 15px;
            margin-right: 7.5px;
          }
          .label {
            color: #dd001b;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 26.25px;
          }
          .beta {
            color: #8d54d6;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px; /* 125% */
            padding: 1px 6px;
            border-radius: 16px;
            border: 0.5px solid #8d54d6;
            margin-left: 5px;
          }
        }
      }
    }
  }
</style>
